import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  IconButton,
  Stack
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LocationAutocomplete from './locationAutocomplete'
import { useApiClient } from '../../context/apiClient';
import { useAuth } from '../../context/authContext';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Cropper from '../cropper'
dayjs.extend(customParseFormat);

const getRelations = (relations) => {
  let rel;
  for (let i = 0; i < relations.length; i += 1) {
    const relationsType = relations[i].relationships.type
    if (relationsType === "PARENT_OF") {
      rel = "parent"
      break
    }
    if (relationsType === "SIBLING_OF") {
      rel = "sibling"
      break
    }
    if (relationsType === "CHILD_OF") {
      rel = "child"
      break
    }
    if (relationsType === "SPOUSE_OF") {
      rel=  "spouse"
      break
    }
  }
  return rel
  
}

const Preview = ({file}) => {
  if (file) {
    let img
    if (typeof(file) === "string") {
      img = file
    } else {
      img = URL.createObjectURL(file)
    }
    return <img style={{height: 50, width: 50, borderRadius: 25, marginLeft: 5}} src={img}/>
  }
  return null
}


export default function AddUserDialog({ open, onClose, selectedUser }) {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    isAlive: true,
    livesIn: true,
    phoneNumber: '',
    email: '',
    file: null,
    dob: null,
    dod: null,
    occupation: '',
    occupationLocation: '',
    occupationLatLng: null,
    relationType: '',
  });
  const [member, setMember] = useState()
  const apiClient = useApiClient()
  const [relatives, setRelatives] = useState()
  const [relations, setRelations] = useState()
  const [imgBeforeCrop, setImgBeforeCrop] = useState(null)
  const { user } = useAuth();
  
  const fetchRelatives = async (userId) => {
    const data = apiClient.post('/graphql', {"query":"query getUserWithRelations($id: ID!) { getUserWithRelations(id: $id) { children {id firstName} parents {id firstName} siblings{id firstName} spouse{id firstName}} }", variables:{id: userId}})
      .then(response => {
          if (response.data.data && response.data.data.getUserWithRelations) {
            setRelatives(response.data.data.getUserWithRelations)
          }
      })
      .catch(err => console.error(err));
};


const fetchRelations = async (userId, selectedUser) => {
    console.log(userId, selectedUser)
  const data = apiClient.post('/graphql', {"query":"query FindRelationsBetweenPersons($personAId: ID!, $personBId: ID!) { findAllRelationsBetweenPersons(personAId: $personAId, personBId: $personBId) { relationships { type} endNode {firstName id}  } }", variables:{personAId: selectedUser, personBId: userId}})
    .then(response => {
      console.log(response)      
        if (response.data.data && response.data.data.findAllRelationsBetweenPersons) {
          setRelations(response.data.data.findAllRelationsBetweenPersons)
        }
    })
    .catch(err => console.error(err));
};


const fetchUser = async(selectedUser) => {
  const data = apiClient.post('/graphql', {"query":"query getPersonById($id: ID!) { personById(id: $id) { firstName lastName phoneNumber image email gender isAlive dob dod occupation occupationLocation} }", variables:{id: selectedUser}})
      .then(response => {
          if (response.data.data && response.data.data.personById) {
            setMember(response.data.data.personById)
            //setRelatives(response.data.data.getUserWithRelations)
          }
      })
      .catch(err => console.error(err));
}
  useEffect(()=>{
    if (user.userId && selectedUser) {
      fetchRelatives(user.userId)
    }
    if (user.userId && selectedUser) {    
      
      fetchRelations(user.userId, selectedUser)
    }
  }, [user, selectedUser])
  console.log(selectedUser)
  useEffect(()=> {
    if (member) {
      setFormValues({...formValues,
        firstName: member.firstName,
        lastName: member.lastName,
        gender: member.gender,
        isAlive: member.isAlive,
        livesIn: true,
        phoneNumber: member.phoneNumber,
        email: member.email,
        file: member.image,
        dob: member.dob,
        dod: member.dod,
        occupation: member.occupation,
        occupationLocation: member.occupationLocation,
        occupationLatLng: member.occupationLatLng,
      })
    }

  }, [member])


  useEffect(()=>{
    if (relations) {
      const rel = getRelations(relations)
      setFormValues((prevState)=>({...prevState, relationType: rel}))
    }
  }, [relations])

  useEffect(()=>{
    if (selectedUser) {
      fetchUser(selectedUser)
    }
  }, [selectedUser])
  const disableSubmit = formValues.isAlive ? !(formValues.firstName && formValues.gender && formValues.relationType && formValues.dob) : !(formValues.firstName && formValues.gender && formValues.relationType && formValues.dod)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgBeforeCrop(reader.result);
      };
      reader.readAsDataURL(file);
    }
    //setFormValues({ ...formValues, file: e.target.files[0] });
  };

  const handleDateChange = (name) => (date) => {
    setFormValues({ ...formValues, [name]: dayjs(date).format("DD/MM/YYYY") });
  };


  const onLocationSelect = (value) => {
    setFormValues({ ...formValues, occupationLocation: value.label, occupationLatLng: `${value.lat}, ${value.lon}` });
  }

  const handleSubmit = async() => {
    // Validation and submission logic here
    try {
      if (selectedUser) {
        const editformValues = new FormData();
        if (formValues.firstName !== member.firstName) {
          editformValues.append("firstName", formValues.firstName)
        }
        if (formValues.lastName !== member.lastName) {
          editformValues.append("lastName", formValues.lastName)
        }
        if (formValues.gender !== member.gender) {
          editformValues.append("gender", formValues.gender)
        }
        if (formValues.email !== member.email) {
          editformValues.append("email", formValues.email)
        }
        if (formValues.dob !== member.dob) {
          editformValues.append("dob", formValues.dob)
        }
        if (formValues.dod !== member.dod) {
          editformValues.append("dod", formValues.dod)
        }
        if (formValues.isAlive !== member.isAlive) {
          editformValues.append("isAlive", formValues.isAlive)
        }
        if (formValues.phoneNumber !== member.phoneNumber) {
          editformValues.append("phoneNumber", formValues.phoneNumber)
        }
        if (formValues.occupation !== member.occupation) {
          editformValues.append("occupation", formValues.occupation)
        }
        if (formValues.occupationLocation !== member.occupationLocation) {
          editformValues.append("occupationLocation", formValues.occupationLocation)
        }
        if (formValues.occupationLatLng !== member.occupationLatLng) {
          editformValues.append("occupationLatLng", formValues.occupationLatLng)
        }
        if (formValues.file !== member.image) {
          editformValues.append("file", formValues.file)
        }
        
        await apiClient.put(`/api/person/${selectedUser}`, editformValues, {
          headers: {'content-type': 'multipart/form-data' }
        })
        resetForm();
        onClose() 
      }
      else {
        await apiClient.post('/api/person', formValues, {
          headers: {'content-type': 'multipart/form-data' }
        })
        resetForm();
        onClose() 
      }
    } catch (e) {
      console.log(e)
    }
    //onClose();
  };
  
  const resetForm = () => {
    setFormValues({
      firstName: '',
      lastName: '',
      gender: '',
      isAlive: true,
      livesIn: true,
      phoneNumber: '',
      email: '',
      file: null,
      dob: null,
      dod: null,
      occupation: '',
      occupationLocation: '',
      occupationLatLng: null,
      relationType: '',
    })
  }
  return (
    <Dialog open={open} onClose={()=>{ resetForm(); onClose() }} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
        {selectedUser ? "Edit User" : "Add User"}
        <Preview file={formValues.file}/>
        </Stack>
        
        </DialogTitle>
        {!imgBeforeCrop && (<>
      <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    label="First Name"
                    name="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Last Name"
                    name="lastName"
                    value={formValues.lastName}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    value={formValues.phoneNumber}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label="Email"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl component="fieldset" margin="normal" required>
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                    name="gender"
                    value={formValues.gender}
                    onChange={handleChange}
                    row
                >
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControlLabel
                control={
                    <Checkbox
                    checked={formValues.isAlive}
                    onChange={handleCheckboxChange}
                    name="isAlive"
                    />
                }
                label="Is Alive"
                />
                 {formValues.isAlive && (<FormControlLabel
                control={
                    <Checkbox
                    checked={formValues.livesIn}
                    onChange={handleCheckboxChange}
                    name="livesIn"
                    />
                }
                label="Lives in"
                />)}
            </Grid>
        
            <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date of Birth"
                        value={formValues.dob ? dayjs(formValues.dob, "DD/MM/YYYY") : dayjs()}
                        onChange={handleDateChange('dob')}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
                {!formValues.isAlive && (<LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    label="Date of Death"
                    value={formValues.dod ? dayjs(formValues.dod, "DD/MM/YYYY") : dayjs()}
                    onChange={handleDateChange('dod')}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                    disabled={formValues.isAlive}
                    />
                </LocalizationProvider>)}
            </Grid>
            <Grid item xs={6}>
                <TextField
                label="Occupation"
                name="occupation"
                value={formValues.occupation}
                onChange={handleChange}
                fullWidth
                margin="normal"
                />
            </Grid>
            <Grid item xs={6}>
                <LocationAutocomplete name="occupationLocation" onLocationSelect={onLocationSelect} value={member?.occupationLocation}/>
                {/*<TextField
                label="Occupation Location"
                name="occupationLocation"
                value={formValues.occupationLocation}
                onChange={handleChange}
                fullWidth
                margin="normal"
                />*/}
            </Grid>
            <Grid item xs={6}>
                {selectedUser !== user.userId && (<FormControl fullWidth margin="normal" required>
                    <InputLabel>Relation</InputLabel>
                    <Select
                        name="relationType"
                        value={formValues.relationType}
                        onChange={handleChange}
                        disabled={user.userId === selectedUser}
                    >
                        <MenuItem value="parent">Parent</MenuItem>
                        {Array.isArray(relatives?.parents) && relatives?.parents?.length > 1 && (<MenuItem value="sibling">Sibling</MenuItem>)}
                        {Array.isArray(relatives?.spouse) && relatives?.spouse?.length > 0 && (<MenuItem value="child">Child</MenuItem>)}
                        <MenuItem value="spouse">Spouse</MenuItem>
                    </Select>
                </FormControl>)}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
            <Button variant="contained" component="label">
                Upload Photo
                <input
                    type="file"
                    hidden
                    onChange={handleFileChange}
                    accept="image/*"
                />
            </Button>
            </Grid>
        
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{ resetForm(); onClose() }}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={disableSubmit}>
          Submit
        </Button>
      </DialogActions>
      </>)}
      <Cropper image={imgBeforeCrop} setImage={(file)=>{setFormValues({ ...formValues, file: file });}} setImgBeforeCrop={setImgBeforeCrop} aspect={1}/>
    </Dialog>
  );
}
