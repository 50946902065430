import Route from './routes'
import './App.css';

function App() {
  return (
   <Route/>
  );
}

export default App;
