import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddHomeIcon from '@mui/icons-material/AddHome';
import HomeIcon from '@mui/icons-material/Home';
import { useAuth } from '../context/authContext';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

export default function SwipeableTemporaryDrawer({open, setOpen, setInfoOpen, setShowAddMember}) {
  const { user } = useAuth();
  const navigate = useNavigate()
  console.log(user)
  const toggleDrawer =
    (_open) =>
    (event) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event).key === 'Tab' ||
          (event).key === 'Shift')
      ) {
        return;
      }
      setOpen( _open );
    };


  return (
    <div>
        <React.Fragment>
          <SwipeableDrawer
            anchor={'left'}
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
          >
              <Box
                role="presentation"
                sx={{width:250}}
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
                >
                    <Box sx={{padding:5}}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ display: { sm: 'block' } }}
                        >
                            Moochikkoodan Family
                        </Typography>
                    </Box>
                <List>
                <Divider />
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setInfoOpen(true)}}>
                        <ListItemIcon>
                            {user.house ? <HomeIcon/>: <AddHomeIcon />}
                        </ListItemIcon>
                        <ListItemText primary={user.house ? 'Edit House' : 'Add House'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton onClick={()=>{setShowAddMember(true)}}>
                        <ListItemIcon>
                           <BorderColorIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Edit User" />
                        </ListItemButton>
                    </ListItem>
                    {user && (<ListItem disablePadding>
                        <ListItemButton onClick={()=>{localStorage.clear(); window.location.reload()}}>
                        <ListItemIcon>
                           <LogoutIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                        </ListItemButton>
                    </ListItem>)}
                    
                </List>
                <Divider />
                </Box>
          </SwipeableDrawer>
        </React.Fragment>
    </div>
  );
}