import React, { useState, useCallback } from 'react';
import { Button, Box, Slider, Stack } from '@mui/material';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils'; // Utility function for cropping

const ImageUploadAndCrop = ({image, setImage, setImgBeforeCrop, aspect}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (!image) return;
    try {
      const croppedImage = await getCroppedImg(image, croppedArea);
      const response = await fetch(croppedImage);
    const blob = await response.blob();

    // Create a File object from the blob
    const file = new File([blob], "cropped_image.jpg", { type: blob.type });
      setImage(file)
      setImgBeforeCrop(null)
      //console.log("Cropped Image: ", croppedImage);
    } catch (error) {
      console.error("Error cropping image: ", error);
    }
  };

  return image ? (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: 300, height: 300, position: 'relative' }}>
            <Box>
            <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={aspect} // Set your desired aspect ratio here
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
            />
          </Box>
          <Box>
            <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, zoom) => setZoom(zoom)}
                sx={{ marginTop: 2 }}
            />
            </Box>
        </Box>
        <Stack direction="row">
      <Button variant="contained" onClick={handleCrop} sx={{ marginTop: 2, mr: 2 }}>
        Crop Image
      </Button>
      <Button variant="contained" onClick={()=> {setImgBeforeCrop(null)}} sx={{ marginTop: 2 }}>
        Cancel
      </Button>
      </Stack>
    </Box>
  ) : null;
};

export default ImageUploadAndCrop;
