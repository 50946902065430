import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Check if user data is in localStorage when the app loads
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    const login = (userInfo) => {
        // Save user data to state and localStorage
        
        console.log(userInfo)
        const data = jwtDecode(userInfo.token)
        
        const userObj = { token: userInfo.token, role: data.role, userId: data.id, username: data.username, house: data.house }
        setUser(userObj);
        localStorage.setItem('user', JSON.stringify(userObj));
    };

    const logout = () => {
        // Clear user data from state and localStorage
        setUser(null);
        localStorage.removeItem('user');
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
