import React, { useEffect, useState, useRef } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Button, CircularProgress } from '@mui/material';
import Point from 'ol/geom/Point'
import Feature from "ol/Feature";
import VectorSource from "ol/source/Vector";
import VectorLayer from 'ol/layer/Vector';
import {Style, Icon, Text, Stroke, Circle as CircleStyle, Fill} from 'ol/style';


const UserLocationMap = ({inLat, inLon, setLatLon}) => {
  console.log(inLat, inLon)
  // Ensure the map uses geographic coordinates
    const [map, setMap ] = useState()
    const btnRef = useRef(null);
    const mapRef = useRef(null);
    const [center, setCenter] = useState(fromLonLat([inLat, inLon]));
    const [locLoading, setLocLoading] = useState(false)
    


    useEffect(() => {
     
      const viewOptions = {
        center: center,
        zoom: 18,
      }

      
      const initialMap = new Map({
        target: mapRef.current,
        layers: [
          new TileLayer({
            source: new OSM(),
            name:'tile'
          }),
        ],
        view: new View(viewOptions),
      });
                       
      setMap(initialMap);

      let markerLayer;

      const clearMarkerLayer = () => {
        if (markerLayer) {
          initialMap.removeLayer(markerLayer);
        }
      };
    
      const addMarkerLayer = (loc) => {
        clearMarkerLayer();
        const markerFeature = new Feature({
          geometry: new Point(loc),
          clickable: false
        });
        
        let iconStyle;
        let markerImg = './marker.png'

        iconStyle = new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: markerImg,
            scale: 0.075
          })
        });
  
        markerFeature.setStyle(iconStyle);
  
        const markerSource = new VectorSource({
          features: [markerFeature],
        });

        markerLayer = new VectorLayer({
          source: markerSource,
          name:'marker',
        })

        initialMap.addLayer(markerLayer);

      };
      if (inLat && inLon) {
        addMarkerLayer(center)
      }

      btnRef.current.addEventListener('click', ()=>{ 
        setLocLoading(true)
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLatLon(`${longitude}, ${latitude}`)
           addMarkerLayer(fromLonLat([longitude, latitude]))
           initialMap.getView().setCenter(fromLonLat([longitude, latitude]));
           setLocLoading(false)
          },
          (error) => {
            setLocLoading(false)
            console.error('Error getting location:', error);
          }
        );
        
        
      })
      
    },[]) 



  return (
    <div>
      <div ref={mapRef} style={{ width: '100%', height: '400px', marginBottom: 10, marginTop: 10 }}></div>
      <Button disabled={locLoading} ref={btnRef} variant="contained" endIcon={locLoading && <CircularProgress size={20} sx={{ color: "white" }}/>}>{inLat && inLon ? "Update Location" : "Get Location"}</Button>
    </div>
  );
};

export default UserLocationMap;
