import axios from 'axios';
import { useAuth } from './authContext';
import { isTokenExpired } from './tokenContext.js';

export const useApiClient = () => {
    const { user, setUser } = useAuth();

    const apiClient = axios.create();

    apiClient.interceptors.request.use(async (config) => {
        const accessToken = user?.token;
        if (isTokenExpired(accessToken)) {
            // Handle the case where token refresh failed
            // e.g., redirect to login
            setUser(null);
            localStorage.removeItem('user');
            window.location.href = '/login';
        } else {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    });

    return apiClient;
};

