
import { useEffect, useState } from 'react';
import MapView from '../components/mapView.js'
import AppBar from '../components/appbar.js'
import AddHouse from '../components/addHouse'
import AddMember from '../components/addMember'
import { useParams } from 'react-router-dom';
import { useApiClient } from '../context/apiClient';
import { useAuth } from '../context/authContext';


function MyMap(props) {
  const { id } = useParams();
  const { user } = useAuth()
  const [isDetailOpen, setIsDetailOpen] = useState(id ? true : false);
  const [markers, setMarkers] = useState([])
  const [selectedHouse, setSelectedHouse] = useState()
  const [showAddHouse, setShowAddHouse] = useState(false)
  const [showAddMember, setShowAddMember] = useState(false)
  const [selectedUser, setSelectedUser] = useState();

  const apiClient = useApiClient();

    const fetchHouses = async () => {
          const data = apiClient.post('/graphql', {"query":"{ getAllHouses { id, image, thumb, address, latLong } }"})
            .then(response => {
                if (response.data.data && response.data.data.getAllHouses) {
                    setMarkers(response.data.data.getAllHouses)
                }
            })
            .catch(err => console.error(err));
    };

  useEffect(() => {
    if (!id && markers.length < 1) {
        fetchHouses();
    }
  }, [id]);
  
  useEffect(()=> {
    if (id) {
      setSelectedHouse(id);
      setIsDetailOpen(true)
    } else {
        setSelectedHouse(undefined);
      setIsDetailOpen(false)

    }
}, [id])

  const addHouseClose = () => {
    setShowAddHouse(false)
  }
  const addMemberClose = () => {
    setShowAddMember(false)
    setSelectedUser(null)
  }
  useEffect(()=>{
    if (showAddMember) {
      setSelectedUser(user.userId)
    }
  }, [showAddMember])
  return (
    <div>
      <AppBar setOpen={setShowAddHouse} setShowAddMember={setShowAddMember}/>
      <MapView 
        markers={markers}
        selectedHouse={selectedHouse}
        setSelectedHouse={setSelectedHouse}
        isDetailOpen={isDetailOpen}
        setIsDetailOpen={setIsDetailOpen} />
        <AddHouse onClose={addHouseClose} open={showAddHouse}/>
        <AddMember onClose={addMemberClose} open={showAddMember} selectedUser={selectedUser}/>
    </div>
  )
}
export default MyMap;
