import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { TextField, Autocomplete } from '@mui/material';
const debounce = (func, wait) => {
    let timeout;
    
    return function(...args) {
      const context = this;
      
      clearTimeout(timeout);
      
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }
  

const LocationAutocomplete = ({ onLocationSelect, value }) => {
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const fetchLocations = useCallback(debounce(async () => {
    try {
      const response = await axios.get('https://nominatim.openstreetmap.org/search', {
        params: {
          q: query,
          format: 'json',
          addressdetails: 1,
          limit: 5,
        },
      });
      setSuggestions(
        response.data.filter(item =>  item.type === "city" || item.type === "suburb" ||item.type === "administrative").map((item) => ({
          label: item.address.suburb ?`${item.address.suburb}, ${item.address.city}` : item.address.city,
          lat: item.lat,
          lon: item.lon,
        }))
      );
    } catch (error) {
      console.error('Error fetching location data:', error);
    }
  }, 1000));
  useEffect(() => {
    if (query.length > 2) {
      fetchLocations();
    } else {
      setSuggestions([]);
    }
  }, [query]);

  return (
    <Autocomplete
      freeSolo
      options={suggestions}
      getOptionLabel={(option) => option.label}
      onInputChange={(event, value) => setQuery(value)}
      onChange={(event, value) => onLocationSelect(value)}
      inputValue={value || ""}
      renderInput={(params) => (
        <TextField {...params} label="Occupation Location" variant="outlined"  fullWidth margin="normal" />
      )}
    />
  );
};

export default LocationAutocomplete;