import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = (token) => {
    if (!token) return true;

    try {
        const { exp } = jwtDecode(token);
        if (!exp) return true;

        // `exp` is in seconds, while `Date.now()` gives time in milliseconds
        const currentTime = Date.now() / 1000;
        return exp < currentTime;
    } catch (error) {
        console.error("Invalid token:", error);
        return true;
    }
};