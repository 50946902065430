import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from "ol/source/Vector";
import { Cluster} from 'ol/source';
import OSM from 'ol/source/OSM';
import Feature from "ol/Feature";
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point'
import {boundingExtent} from 'ol/extent.js';
import Overlay from 'ol/Overlay';
import {Style, Icon, Text, Stroke, Circle as CircleStyle, Fill} from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useTheme } from "@mui/material/styles";
import { FilledInput } from '@mui/material';
import { MarkUnreadChatAltSharp } from '@mui/icons-material';


function MyMap({markers, selectedHouse, setSelectedHouse, isDetailOpen, setIsDetailOpen}) {

  const mapRef = useRef(null);
  const [map, setMap] = useState();

  const [zoom, setZoom] = useState(10)
  const [center, setCenter] = useState(fromLonLat([76.1451513641934,10.94781406648077]));
  const navigate = useNavigate();

  useEffect(() => {
      if (markers && markers.length > 0) {

          const features = markers.map(marker => {
              const latLong = marker.latLong.split(',')
              return  new Feature({geometry: new Point(fromLonLat(latLong)), id: marker.id})
          })

          const vectorSource = new VectorSource({
            features,
          });

          const clusterSource = new Cluster({
            distance: 40, // Distance in pixels within which points will be clustered
            source: vectorSource,
          });

          const clusterLayer = new VectorLayer({
            source: clusterSource,
            style: (feature) => {
              const size = feature.get('features').length;
              const style = new Style({
                image: new Icon({
                    src: '/marker.png', // Replace with your custom icon URL
                    scale: 0.1, // Adjust the scale to fit your needs
                  }),
              });
              return style;
            },
          });

        const viewOptions = {
            center: center,
            zoom: 10,
          }
          const initialMap = new Map({
            target: mapRef.current,
            layers: [
              new TileLayer({
                source: new OSM(),
                name:'tile'
              }),
              clusterLayer
            ],
            view: new View(viewOptions),
          });

          setMap(initialMap);

          initialMap.getView().on('change:resolution', (event) => {
              const zoom = initialMap.getView().getZoom();
              setZoom(zoom);
          });
          initialMap.on('singleclick', (event) => {
            initialMap.forEachFeatureAtPixel(event.pixel, (feature) => {
              const features = feature.get('features');
              if (features.length === 1) {
                const id = features[0].get('id');
                setSelectedHouse(id);
                navigate(`/house/${id}`)
              } else {
                console.log(`Cluster with ${features.length} markers clicked`);
              }
            });
          });
      }
    
  },[markers])


    const recenter = () => {
      map.getView().setCenter(fromLonLat([76.1451513641934,10.94781406648077]));
    }

    const theme = useTheme();

    return (
        <div style={{height:'calc(100vh - 60px)', width:'100%', position:'relative', overflowY:'scroll'}}>
            <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
            <div style={{
              width: 50,
              backgroundColor:theme.palette.primary.main,
              height: 50,
              borderRadius: 25,
              position: 'absolute',
              bottom: 150,
              right: 50,
              zIndex: 3,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
              }}><MyLocationIcon onClick={()=>{recenter()}} sx={{color:'white'}}/></div>
        </div>
    );  
}

export default MyMap;
