import React, { useState, useEffect } from 'react';
import { Grid, TextField, Button, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { AddLocation as AddLocationIcon, CopyAll } from '@mui/icons-material';
import { useAuth } from '../../context/authContext';
import { useApiClient } from '../../context/apiClient';
import Cropper from '../cropper'
import MapView from './mapView'

const Preview = ({file}) => {
  if (file) {
    let img = file
    if (typeof(file) !== "string") {
      img = URL.createObjectURL(file)
    }
    return <img style={{height: 50, width: 50, borderRadius: 25, marginLeft: 5}} src={img}/>
  }
  return null
}

const AddHouseForm = ({open, onClose}) => {
  const [houseDetails, setHouseDetails] = useState()
  const [address, setAddress] = useState('');
  const [image, setImage] = useState(null);
  const [imgBeforeCrop, setImgBeforeCrop] = useState(null)
  const [latLon, setLatLon] = useState()
  const { user } = useAuth();
  const apiClient = useApiClient()

  const [lat, lon] = latLon ? latLon.split(',') : [0, 0]

  const fetchHouse = async (id) => {
    if (id) {
        try {
            const data = {
                query: 'query getHouseById($id: ID!) { houseById(id: $id) { id address thumb image latLong } }',
                variables: {id: id}
              }
            const response = await apiClient.post('/graphql', data)
            
            if (response.data.data && response.data.data.houseById) {
                const houseData = response.data.data.houseById
                setHouseDetails(houseData)
                setAddress(houseData.address)
                setImage(houseData.image)
                setLatLon(houseData.latLong)
            }
        } catch (e) {
            console.log(e)
        }
    }
  }

  useEffect(()=> {
    fetchHouse(user.house)
  }, [user.house])

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgBeforeCrop(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGetLocation = () => {
    // Logic to get location can be added here
    console.log('Getting location...');
  };

  const handleSubmit = async() => {
    // Logic to handle form submission
    if (user && user.house) {
      try {
        const formValues = new FormData();
        if (address !== houseDetails.address) {
          formValues.append("address", address)
        }
        if (latLon !== houseDetails.latLong) {
          formValues.append("latLong", latLon)
        }
        if (image !== houseDetails.image) {
          formValues.append("file", image)
        }
        
        await apiClient.put(`/api/house/${user.house}`, formValues, {
          headers: {'content-type': 'multipart/form-data' }
        })
        onClose()
      } catch (e) {
        console.log(e)
      }
    } else{
      try {
        if (address && image && latLon) {
          const formValues = new FormData();
          formValues.append("address", address)
          formValues.append("latLong", latLon)
          formValues.append("file", image)
          await apiClient.post('/api/house', formValues, {
            headers: {'content-type': 'multipart/form-data' }
          })
          onClose()
        }
      } catch (e) {
        console.log(e)
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>
      <Stack direction="row" alignItems="center">
      {user?.house ? <Typography>Edit House </Typography>: <Typography>Add House</Typography>}
      <Preview file={image}/>
      </Stack>
      
      </DialogTitle>
     {!imgBeforeCrop && ( <>
    <DialogContent>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          label="Address"
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={address}
          onChange={handleAddressChange}
        />
      </Grid>

      <Grid item>
        <Button variant="contained" component="label">
          Upload Image
          <input
            type="file"
            hidden
            onChange={handleImageChange}
            accept="image/*"
          />
        </Button>
      </Grid>
    </Grid>
    <MapView inLat={lat} inLon={lon} setLatLon={setLatLon}/>
    </DialogContent>
    <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
      </>)}
      <Cropper image={imgBeforeCrop} setImage={setImage} setImgBeforeCrop={setImgBeforeCrop} aspect={16/9}/>
    </Dialog>
  );
};

export default AddHouseForm;
