// src/pages/SetPasswordPage.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Paper, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const SetPasswordPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Implement your password update logic here
    const options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"username": username, "password": password})
      };
      
      fetch('/api/set-password', options)
        .then(response => response.json())
        .then(response => {
            if (response.ok) {
                navigate('/login');
            }
        })
        .catch(err => {
            console.error(err)
            setError("Something went wrong")
        });

    // Reset the form and error message
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setError('');
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh', // Makes sure it takes the full viewport height
        padding: 2
      }}
    >
      <Paper elevation={3} sx={{ padding: 4 }}>
        <Typography variant="h5" align="center">
          Set Password
        </Typography>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} marginTop={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Set Password
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default SetPasswordPage;
