import React, { useState, useEffect } from 'react';
import { Typography, Paper, Stack, Box, Avatar, Accordion, AccordionSummary, AccordionDetails, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandMore } from '@mui/icons-material';
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useAuth } from '../context/authContext';
import { useApiClient } from '../context/apiClient';
import AddMemberPopUp from '../components/addMember';
import dayjs from 'dayjs'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import PhoneIcon from '@mui/icons-material/Phone';
import EditIcon from '@mui/icons-material/Edit';
dayjs.extend(customParseFormat);

const gotoLoc = (latLong) =>{
    const _latLong = latLong.split(",")
    window.open(`https://www.google.com/maps/search/?api=1&query=${_latLong[1]},${_latLong[0]}`);
}

const calculateNextBirthday = (dob) => {
    if (dob) {
        const today = dayjs(); // Get today's date
        const birthDate = dayjs(dob, 'DD/MM/YYYY'); // Parse the date of birth with the specified format

        // Set the birthday for the current year
        let nextBirthday = dayjs(`${today.year()}-${birthDate.format('MM-DD')}`);

        // If the birthday has already passed this year, set it to next year
        if (today.isAfter(nextBirthday, 'day')) {
            nextBirthday = nextBirthday.add(1, 'year');
        }

        // Calculate the difference in days to the next birthday
        const daysUntilNextBirthday = nextBirthday.diff(today, 'day');

        return {
            nextBirthday: nextBirthday.format('DD/MM/YYYY'),
            daysUntilNextBirthday
        };
    }
}

const calculateAge = (dob) => {
    if (dob) {
        return dayjs().diff(dayjs(dob, 'DD/MM/YYYY'), 'year'); // Calculate the difference in years
    }
}

const Member = ({member, setSelectedUser, setShowAdd, user}) => {
    const age =  calculateAge(member.dob)
    const nextBday = calculateNextBirthday(member.dob)    
    const canEdit = user.role === "house-admin" && member.id !== user.userId
    const shadeColor = member.gender === "female" ? "#800080" : "blue"
    return (
    <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
        <Stack direction="row" alignItems="center">
            <Avatar alt={member.firstName} src={member.thumb} sx={{mr: 2, boxShadow: `0 0px 10px ${shadeColor}`}}/>
            <Stack direction="row"><Typography sx={{mr: 1}}>{member.firstName} </Typography> <Typography>{member.lastName}</Typography></Stack>
        </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{position: 'relative'}}>
              <Stack>
                {age && (<Stack direction="row" alignItems="center" sx={{mb: 2}}>
                    <Typography sx={{mr: 2}}>Age : {age}</Typography>
                    <Typography sx={{ fontSize: 12 }}>Next Birthday on {nextBday.nextBirthday} (in {nextBday.daysUntilNextBirthday} days)</Typography>
                </Stack>)}
                {member.occupation	 && (<Stack direction="row" alignItems="center" sx={{mb: 2}}>
                    <Typography sx={{mr: 2}}>Job: {member.occupation	}</Typography>
                    {member.occupationLocation&& (<Typography sx={{ fontSize: 12 }}>at {member.occupationLocation}</Typography>)}
                </Stack>)}
                {member.phoneNumber && (<Stack direction="row" sx={{mb: 2}}>
                    <PhoneIcon/>
                    <Typography sx={{ml:2}}>{member.phoneNumber}</Typography>
                </Stack>)}
              </Stack>
              {canEdit && (<IconButton onClick={()=> { setSelectedUser(member.id); setShowAdd(true) } } sx={{ position: 'absolute', right: 0, bottom: 0, width: 50, height: 50, borderRadius:25, backgroundColor: 'primary.main', color: "white", "&:hover":  { backgroundColor: 'primary.main' }}}><EditIcon/></IconButton>)}
          </Box>
        </AccordionDetails>
    </Accordion>
    )
}
const Members = ({members, setShowAdd, house, setSelectedUser}) => {
    const { user } = useAuth();
    
    const showAddButton = user.role==="house-admin" && user.house === house
    if (members) {
        return (
        <Box sx={{mt: 4, padding: 2}}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant='h4'>Members</Typography> 
                {showAddButton && (<Button variant="contained" endIcon={<PersonAddIcon />} onClick={()=>{setShowAdd(true)}}>Add Member</Button>)}
            </Stack>
            
            <Stack>
                {members.map(member => <Member key={member.id} member={member} setSelectedUser={setSelectedUser} setShowAdd={setShowAdd} user={user}/>)}
            </Stack>
        </Box>
        )
    }
    return null
}

function Popup() {
   const { id } =  useParams()
   const apiClient = useApiClient()
   const [detail, setDetails] = useState()
   const [showAdd, setShowAdd] = useState(false)
   const [selectedUser, setSelectedUser] = useState()

  const navigate = useNavigate();
  
    const getDetail = async(_id) => {
        if (_id) {
            try {
                const data = {
                    query: 'query getDetailsOfHouse($id: ID!) { getDetailsOfHouse(id: $id) { house { id address latLong image } residents { id firstName lastName thumb gender dob occupation occupationLocation phoneNumber } } }',
                    variables: {id: _id}
                  }
                const response = await apiClient.post('/graphql', data)
                if (response.data.data && response.data.data.getDetailsOfHouse) {                    
                    setDetails(response.data.data.getDetailsOfHouse)
                }
            } catch (e) {
                console.log(e)
            }
        }
    }


    const theme = useTheme();
useEffect(()=> {
    getDetail(id)
}, [id])

    if (detail) {
        return (
            <Paper sx={{width:{md:'50%', zIndex:5, sm: '100%', display:'flex', flexDirection: 'column', position:'absolute', top:0, right:0, height:'100%', width:'100%', overflowY:'scroll'}}}>
            <div style={{position:'relative', width:'100%'}}>
                <CloseIcon onClick={()=>{navigate('/')}} sx={{position:'absolute', padding: '2px', top:10, zIndex:6, right:10, cursor:'pointer', stroke: "#ffffff", color:'white', strokeWidth: 0.5, width: '25px', height: '25px', backgroundColor:'black', borderRadius: '15px'}}/>
                <img src={detail.house.image} style={{maxWidth: '100%'}}/>
                <Members members={detail.residents} setShowAdd={setShowAdd} house={id} setSelectedUser={setSelectedUser}/>
            </div>
            <AddMemberPopUp open={showAdd} onClose={()=>{setSelectedUser(null); setShowAdd(false)}} selectedUser={selectedUser}/>
            </Paper>
        );
    }
}


export default Popup;
