import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from './pages/Landing.js'
import LoginPage from './pages/Login.js'
import DetailPage from './pages/Detail.js'
import SetPasswordPage from './pages/SetPassword.js'
import PrivateRoute from './privateRoute.js';
import { AuthProvider, useAuth } from './context/authContext';

function App() {
  return (
    <AuthProvider>
        <Router>
            <Routes>
                <Route path="/login" element={<LoginRedirectWrapper />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/house/:id" element={<DetailPage />} />
                </Route>
                <Route path="*" element={<Navigate to="/landing" replace />} />
                <Route path="/set-password" element={<SetPasswordPage />} />
            </Routes>
        </Router>
    </AuthProvider>
  )
}

function LoginRedirectWrapper() {
  const { user } = useAuth();
  return user ? <Navigate to="/" replace /> : <LoginPage />;
}


export default App;
